.orderContainer{
    width:95%;
    background-color: #E9ECEF;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 15px 15px 15px 15px;
 }
.orderTopRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid gray;
}
.orderTopRowTitle{
    flex:5;
    padding-left:0.5em;
    font-size:1em;
}
.orderTopRowAction{
    flex:1;
    text-align: right;
    padding-right:0.5em;
}

.orderItemPriceContainer{
    display: flex;
    flex-direction: row;
    width:100%;
}
.orderItem{
    flex:5;
    text-align: right;
    padding-right:30px;
    font-size:small;
}
.orderPrice{
    padding-right:10px;
    flex:2;
    font-size: small;
    text-align: right;
}
.subTotal{
    font-weight: bold;;
}
.orderTotalItem{
    flex:5;
    text-align: right;
    padding-right:20px;
    font-size:strong;
}

.orderTotalPrice{
    padding-right:38px;
    flex:2;
    font-weight: small;
    text-align: right;
}
.fa-trash{
    cursor:pointer;
}

.orderDetailContinue{
    text-align: right;

}
.continueItem{
    float: right;
}