
// $body-bg: #000;
$theme-colors: (
    "info": rgb(145, 147, 153),
    // "danger": tomato,
    "secondary": rgb(129, 123, 129),
    // default
    "primary": #2266E0,
    "accent": rgb(213, 66, 51),
);

// // @import "../../node_modules/bootstrap-scss/variables";
// @import "../../node_modules/bootstrap-scss/bootstrap";


@import "../../node_modules/bootstrap-scss/functions";
@import "../../node_modules/bootstrap-scss/variables";
@import "../../node_modules/bootstrap-scss/mixins";
@import "../../node_modules/bootstrap-scss/root";
@import "../../node_modules/bootstrap-scss/reboot";
@import "../../node_modules/bootstrap-scss/type";
@import "../../node_modules/bootstrap-scss/images";
// @import "../../node_modules/bootstrap-scss/code";
@import "../../node_modules/bootstrap-scss/grid";
// @import "../../node_modules/bootstrap-scss/tables";
@import "../../node_modules/bootstrap-scss/forms";
@import "../../node_modules/bootstrap-scss/buttons";
// @import "../../node_modules/bootstrap-scss/transitions";
@import "../../node_modules/bootstrap-scss/dropdown";
@import "../../node_modules/bootstrap-scss/button-group";
@import "../../node_modules/bootstrap-scss/input-group";
@import "../../node_modules/bootstrap-scss/custom-forms";
@import "../../node_modules/bootstrap-scss/nav";
// @import "../../node_modules/bootstrap-scss/navbar";
@import "../../node_modules/bootstrap-scss/card";
// @import "../../node_modules/bootstrap-scss/breadcrumb";
// @import "../../node_modules/bootstrap-scss/pagination";
// @import "../../node_modules/bootstrap-scss/badge";
@import "../../node_modules/bootstrap-scss/jumbotron";
//@import "../../node_modules/bootstrap-scss/alert";
@import "../../node_modules/bootstrap-scss/progress";
@import "../../node_modules/bootstrap-scss/media";
@import "../../node_modules/bootstrap-scss/list-group";
@import "../../node_modules/bootstrap-scss/close";
// @import "../../node_modules/bootstrap-scss/toasts";
@import "../../node_modules/bootstrap-scss/modal";
// @import "../../node_modules/bootstrap-scss/tooltip";
// @import "../../node_modules/bootstrap-scss/popover";
// @import "../../node_modules/bootstrap-scss/carousel";
// @import "../../node_modules/bootstrap-scss/spinners";
@import "../../node_modules/bootstrap-scss/utilities";
@import "../../node_modules/bootstrap-scss/print";


@import './lib/cards.scss';
@import './lib/loading.scss';
@import './lib/uo.scss';
@import './lib/modal.scss';

.decorated-link {
    cursor: pointer;
    color: #3386FF;
}

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }

.font-80 {
    font-size: 80%;
}
.font-90 {
    font-size: 90%;
}

.full-page-load {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
//   font-size: calc(10px + 2vmin);

}

  
    .adaptive-centerish-content {
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;        
      }
  

  @media screen and (max-width: 992px) {
    .adaptive-centerish-content {
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;        
      }
  }

  @media screen and (max-width: 600px) {
    .adaptive-centerish-content {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;        
      }
  }