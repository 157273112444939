$secondary: '#3386FF';
$white: '#fff';
.card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);  
    margin-top: 10px;
    box-sizing: border-box;
    border-radius: 2px;
    background-clip: padding-box;
    padding: .5em 1em .5em 1em;
    
  }
  
  .card span.card-title {
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    // text-transform: uppercase;
  }
  
  .card .card-image {
    position: relative;
    overflow: hidden;
  }
  
  .card .card-image img {
    border-radius: 2px 2px 0 0;
    background-clip: padding-box;
    position: relative;
    height: 4rem;
    border:1px solid blue;
    width:300px;
    height:200px;
    // z-index: -1;
  }

  .card-img-overlay {
    text-align: right;
  } 
  
  .card .card-image span.card-title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px;
  }
  
  .card .card-content {
    padding: 16px;
    border-radius: 0 0 2px 2px;
    background-clip: padding-box;
    box-sizing: border-box;
  }
  
  .card .card-content p {
    margin: 0;
    color: inherit;
  }
  
  .card .card-content span.card-title {
    line-height: 48px;
  }
  .card-header {
    background-color: $primary;
    color: $white;
  }
  .card .card-action {
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 16px;
  }
  
  .card .card-action a {
    color: #ffab40;
    margin-right: 16px;
    transition: color 0.3s ease;
    text-transform: uppercase;
  }
  
  .card .card-action a:hover {
    color: #ffd8a6;
    text-decoration: none;
  }