.footer {
    background: #222 none repeat scroll 0% 0%;
    color: #A0A0A0;
    padding: 0px 20px 10px;
    border-top: 4px solid #4F8DB3;
    margin-top: 20px;
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.4);
}
/*UOREGON HEADER BAR */			
    .uoregon-bar {
        background: none repeat scroll 0% 0% black;
        color: #fff
    }
    
    .uoregon-bar a {
        color: #fff;
    }
    
    .uoregon-bar a:hover {
        text-decoration: none;
    }
    

    .uonav {
        text-align:right;
        font-family: 'Roboto Slab', serif;
        font-size:.8em;
    }	
